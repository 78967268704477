export const SESSION_SCHEDULE_EVENT_FRAGMENT = `
  fragment sessionScheduleEventFragment on Session {
     uid
      name
      startTime
      startTimestamp
      endTime
      endTimestamp
      geozonesOfEdition: _geozonesOfEdition {
        exhibitHall {
          name
        }
      }
      _isInAgenda(myUid: "%authUser%")
    }
  }
`;
